<template>
    <div style="background-image: url('https://firebasestorage.googleapis.com/v0/b/boxwood-axon-389803.appspot.com/o/background-1.jpg?alt=media&token=b7bc4b89-005a-44ef-8971-f1c90b502cbc'); height: 100%;background-size: cover;">
    <app-navbar></app-navbar>
    <v-main>
        <v-container>
            <div class="about">
                <h1>Terms of Service</h1>

                <h2>1. Introduction</h2>

                <p>
                Welcome to Danang Airport Transfer! These terms and conditions outline the rules and regulations for the use of our application.
                </p>

                <h2>2. Acceptance of Terms</h2>

                <p>
                By accessing or using our application, you agree to be bound by these Terms of Service. If you do not agree with any part of these terms, you must not use the application.
                </p>

                <h2>3. Use of the Application</h2>

                <p>
                Our application is intended for personal, non-commercial use. You must not use the application for any illegal or unauthorized purpose. You are solely responsible for your use of the application and any content you submit.
                </p>

                <h2>4. User Accounts</h2>

                <p>
                To access certain features of the application, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You must not share your account information with others or allow unauthorized access to your account.
                </p>

                <h2>5. Intellectual Property</h2>

                <p>
                The content and materials available in the application, including text, graphics, images, logos, and software, are owned or licensed by Danang Airport Transfer and protected by applicable copyright and intellectual property laws. You may not reproduce, modify, distribute, or otherwise use any part of the application without our express written permission.
                </p>

                <h2>6. Disclaimer</h2>

                <p>
                Our application is provided on an "as is" and "as available" basis, without any warranties of any kind, express or implied. We do not warrant that the application will be uninterrupted, error-free, or secure, or that any defects will be corrected.
                </p>

                <h2>7. Limitation of Liability</h2>

                <p>
                In no event shall Danang Airport Transfer or its affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use of the application or these Terms of Service, even if advised of the possibility of such damages.
                </p>

                <h2>8. Governing Law</h2>

                <p>
                These Terms of Service shall be governed by and construed in accordance with the laws of Viet Nam, without regard to its conflict of laws principles.
                </p>

                <h2>9. Changes to the Terms</h2>

                <p>
                We reserve the right to modify or replace these Terms of Service at any time. Any changes will be effective immediately upon posting the updated Terms on this page. Your continued use of the application after such changes constitutes acceptance of the modified Terms.
                </p>

                <h2>10. Contact Us</h2>

                <p>
                If you have any questions or concerns about these Terms of Service, please contact us at terms@danangairporttransfer.com.
                </p>
            </div>
        </v-container>
    </v-main>
    </div>
    <AppBottom/>
  </template>
  
  <script>
  import AppNavbar from '@/components/AppNavbar.vue';
  import AppBottom from '@/components/AppBottom.vue';
  export default {
    name: "TermsOfService",
    components: {
      AppNavbar,
      AppBottom
    },
  };
  </script>
  
  <style>
  .about {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  </style>
  