<template>
  <div class="custom-input">
      <span class="p-float-label" >
        <Calendar v-bind:modelValue="DatePickerValue" @update:modelValue="setDate" :minDate="minDate" dateFormat="dd/mm/yy" touchUI></Calendar>
        <label for="birth_date">Pick-Up Date</label>
      </span>
      <div class="v-input__details"></div>
  </div>
  
</template>

<style>
.custom-input {
  position: relative;
}

.custom-input input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ababab;
  border-radius: 0;
  height: 52px;
  font-size: 16px;
  padding: 0px;
}


.custom-input input:focus {
  outline: none;
  border-bottom: 2px solid purple;
}

.custom-input input:not(:placeholder-shown) ~ label {
  top: -16px;
  font-size: 12px;
  color: purple;
  font-weight: bold;
  transition: top 0.3s, font-size 0.3s, color 0.3s;
}

.custom-input label {
  position: absolute;
  left: 0 !important;
  top: 8px;
  font-size: 16px;
  pointer-events: none;
  transition: top 0.3s, font-size 0.3s, color 0.3s;
}
.custom-input .p-float-label .p-inputwrapper-filled ~ label{
  top: 0 !important;
  color: #929292 !important;
  font-weight: 400 !important;
}
.p-float-label input:focus ~ label{
  top: 0 !important;
  color: #620eed !important;
  font-weight: 400 !important;
}
</style>

<script>
export default {
name: 'App',
props: {
  DatePickerValue: Date,
},
data() {
  return {
    date:'',
    minDate: '',
  }
},
mounted() {
  this.setMinDate();
},
methods: {
  setDate(date){
    this.$emit('setDate',date);
  },
  setMinDate() {
    const today = new Date();
    const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
    this.minDate = tomorrow;
  },
}
}
</script>