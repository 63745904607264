<template>
    <div style="background-image: url('https://firebasestorage.googleapis.com/v0/b/boxwood-axon-389803.appspot.com/o/background-1.jpg?alt=media&token=b7bc4b89-005a-44ef-8971-f1c90b502cbc'); height: 100%;background-size: cover;">
      <app-navbar></app-navbar>
      <v-main>
        <v-container>
          <v-card
            :loading="isEstimating"
            class="mx-auto"
            max-width="344"
          >
            <template v-slot:loader="{ isActive }">
              <v-progress-linear
                :active="isActive"
                color="deep-purple"
                height="4"
                indeterminate
              ></v-progress-linear>
            </template>
             <v-tabs
              v-if="step==1"
              v-model="tabs"
              @update:modelValue="tabsChange"
              color="teal-darken-2"
              grow
              class="mr-4 ml-4"
            >
              <v-tab value="1" >
                <span class="text-subtitle-2">From Airport</span>
              </v-tab>
              <v-tab value="2">
                <span class="text-subtitle-2">To Airport</span>
              </v-tab>
            </v-tabs>
            <v-window>
              <v-window-item value="1">
                <v-container>
                  <div v-if="step==2">
                    <v-row>
                        <v-col cols="2" sm="2" class="pt-0">
                          <v-card-actions class="px-0 py-0">
                            <v-btn
                              :disabled="isCompleted"
                              color="purple"
                              @click="editItinerary"
                            >
                            <v-icon
                              start
                              icon="mdi-arrow-left"
                            ></v-icon>
                              EDIT
                            </v-btn>
                          </v-card-actions>
                        </v-col>
                        <v-col cols="10" sm="10" >
                          <span class="text-h6 float-right">{{ this.timepicker }} - {{ this.date }}</span>
                        </v-col>
                    </v-row>
                    
                    <span class="text-subtitle-1" style="line-height:2"><v-icon>mdi-map-marker</v-icon> {{ this.tabs==1?this.airportSelect.name:this.pickUpLocation }}</span><br>
                    <span class="text-subtitle-1" style="line-height:2"><v-icon>mdi-map-marker</v-icon> {{ this.tabs==2?this.airportSelect.name:this.dropOffLocation }}</span><br>
                    <span class="text-subtitle-1"><v-icon>mdi-car</v-icon> {{ this.vehicleSelect.name }}</span><br>
                    <v-row no-gutters>
                      <v-col cols="7" sm="7" >
                        <span class="text-red font-weight-bold" style="line-height:2">Pay:</span>
                      </v-col>
                      <v-col cols="5" sm="5">
                        <span class="float-right text-red text-h6">{{this.estimaterate.toLocaleString('vi-VN')}} đ</span>
                      </v-col>
                    </v-row>
                  </div>
                  <GMapAutocomplete
                    v-if="tabs==2&&step==1"
                    :Gmapplaceholder="pickUpPlaceholder"
                    :GmapplaceValue="pickUpLocation"
                    @setPlace="setPlace($event, 'pickUp')"
                  ></GMapAutocomplete> 
                  <v-select
                    v-if="step==1"
                    v-model="airportSelect"
                    :items="airportItems"
                    item-title="name"
                    item-value="code"
                    color="teal-darken-2"
                    :label="tabs==1 ? 'From Airport' : 'To Airport'"
                    variant="underlined"
                    @update:modelValue="estimateRate"
                  ></v-select>
                  
                  <GMapAutocomplete
                    v-if="tabs==1&&step==1"
                    :Gmapplaceholder="dropOffPlaceholder"
                    :GmapplaceValue="dropOffLocation"
                    @setPlace="setPlace($event, 'dropOff')"
                    v-model="dropOffLocation"
                  ></GMapAutocomplete>
    
                  <v-select
                    v-if="step==1"
                    v-model="vehicleSelect"
                    :items="vehicleairportItems"
                    item-title="name"
                    color="teal-darken-2"
                    label="Vehicle type"
                    variant="underlined"
                    return-object
                    @update:modelValue="estimateRate"
                  ></v-select>
                  <v-row no-gutters v-if="step==1">
                    <v-col cols="7" sm="7" >
                      <DatePicker 
                        class="pr-4" 
                        @setDate="setDate($event)"
                        :DatePickerValue="date"
                    />
                    </v-col>
                    <v-col ols="5" sm="5" >
                      <TimePicker 
                          @setTime="setTime($event)"
                          @hideTimepicker="hideTimepicker"
                          :TimePickerValue="time"
                      />
                    </v-col>
                  </v-row>
                  <v-card v-if="step==1" :loading="isEstimating">
                    <template v-slot:loader="{ isActive }">
                      <v-progress-linear
                        :active="isActive"
                        color="deep-purple"
                        height="4"
                        indeterminate
                      ></v-progress-linear>
                    </template>
                    <template v-slot:title>
                      <v-row no-gutters>
                        <v-col cols="7" sm="7" >
                          Estimated rates
                        </v-col>
                        <v-col ols="5" sm="5">
                          <v-progress-circular
                            indeterminate
                            color="green"
                            class="float-right"
                            v-if="isEstimating"
                          ></v-progress-circular>
                          <span v-if="!isEstimating" class="float-right text-red">{{this.estimaterate.toLocaleString('vi-VN')}} đ</span>
                        </v-col>
                      </v-row>
                     
                    </template>
                    <template v-slot:subtitle>
                      <span class="estimated-subtitle">
                        Rates include airport entry fee and overtime pick-up surcharge.<br>
                        Rates does not include VAT
                      </span>
                    </template>
                  </v-card>
                </v-container>
              </v-window-item>
            </v-window>
            <v-divider></v-divider>
            <v-card-actions v-if="step==1">
              <v-spacer></v-spacer>
              <v-btn color="teal-darken-2" @click="continueStep2">
                CONTINUE
                <v-icon icon="mdi-chevron-right" end></v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card
            v-if="step==2 && !isCompleted"
            class="mx-auto mt-2"
            max-width="344"
          >
            <template v-slot:title>
              <span class="text-subtitle-1">Passenger information</span>
            </template>
            <v-container class="py-0">
              <v-text-field
                v-model="name"
                :counter="65"
                label="First and last name (*)"
                color="primary"
                variant="underlined"
              ></v-text-field>
              <v-text-field
                v-model="phone"
                :counter="12"
                type="number"
                label="Phone Number (*)"
                color="primary"
                variant="underlined"
              ></v-text-field>
              <v-radio-group v-model="contactType" inline>
                <template v-slot:label>
                  <strong class="mr-0">How does the driver contact you?</strong>
                </template>
                <v-radio
                  label="WhatsApp"
                  value="whatsapp"
                ></v-radio>
                <v-radio
                  label="Wechat"
                  value="wechat"
                ></v-radio>
                <v-radio
                  label="KakaoTalk"
                  value="kakaotalk"
                ></v-radio>
              </v-radio-group>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer v-if="!isSubmiting"></v-spacer>
              <div class="text-center w-100" v-if="isSubmiting">
                <v-progress-circular
                  indeterminate
                  color="green"
                ></v-progress-circular>
              </div>
              <v-btn color="teal-darken-2"  v-if="!isSubmiting" class="py-2" block @click="completeRegistration">
                Complete Registration
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-if="isCompleted" class="mx-auto mt-2" max-width="344">
          <v-card-text class="text-h6 py-2">
            "We have received your request and are currently searching for a suitable vehicle for you. Our driver will get in touch with you via {{ contactType }} at +{{ phone }} as soon as possible. Thank you for choosing our service!"
          </v-card-text>
          </v-card>
          <div v-if="isCompleted" class="mx-auto mt-2" style="max-width: 344px;">
            <v-btn @click="backToHome" append-icon="mdi-home" block>
              Home
              <template v-slot:append>
                <v-icon color="teal-darken-2"></v-icon>
              </template>
            </v-btn>
          </div>
        </v-container>
      </v-main>
    </div>
    <AppBottom/>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
      rounded="pill"
      color="#F44336"
    >
      <div class="text-center">{{ snackbarText }}</div>
    </v-snackbar>
  </template>
  <style>
  .custom-text-field {
    margin-top: 10px;
  }
  .estimated-subtitle{
    white-space: normal;
    letter-spacing: 0;
  }
  </style>
  <script>
  import AppNavbar from '@/components/AppNavbar.vue';
  import GMapAutocomplete from '@/components/GMapAutocomplete.vue';
  import DatePicker from '@/components/DatePicker.vue';
  import TimePicker from '@/components/TimePicker.vue';
  import AppBottom from '@/components/AppBottom.vue';
  import axios from 'axios';
  import db from '@/main';

  axios.interceptors.request.use((config) => {
    config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE';
    config.headers['Access-Control-Allow-Headers'] = 'Content-Type, Authorization';
    return config;
  });
  export default {
    components: {
      AppNavbar,
      GMapAutocomplete,
      DatePicker,
      TimePicker,
      AppBottom
    },
    data () {
      return {
        step: 1,
        airportSelect: { name: 'Da Nang International Airport', code: 'DAD01' , fullName: 'Sân bay quốc tế Đà Nẵng, Nguyễn Văn Linh, Hòa Thuận Tây, Hải Châu, Đà Nẵng 550000, Việt Nam'},
        airportItems: [
          {name: 'Da Nang International Airport', code: 'DAD01' },
        ],
        vehicleSelect: { name: '4 seater car', code: '4s' },
        vehicleairportItems: [
          { name: '4 seater car', code: '4s' },
          { name: '7 seater car', code: '7s' },
          { name: '9 seater Limousine', code: '9s' }
        ],
        time : null,
        timepicker: null,
        date : null,
        dropOffLocation: null,
        pickUpLocation: null,
        estimaterate: 0,
        bgimage: 'https://danangairporttransfer.com/images/background.jpeg',
        tabs: null,
        dropOffPlaceholder: 'Drop-off location',
        pickUpPlaceholder: 'Pick-up location',
        name: '',
        phone: '',
        contactType: 'whatsapp',
        snackbar: false,
        snackbarText: '',
        snackbarTimeout: 2000,
        isCompleted: false,
        isEstimating : false,
        isSubmiting: false
      }
    },
    mounted(){
    },
    methods:{
      estimateRate(){
        if(this.tabs == 1){
          if(this.airportSelect&&this.dropOffLocation&&this.vehicleSelect&&this.date&&this.time){
            this.calculateDistance(this.airportSelect.fullName, this.dropOffLocation);
          }else{
            this.estimaterate = 0;
          }
        }else{
          if(this.airportSelect&&this.pickUpLocation&&this.vehicleSelect&&this.date&&this.time){
            this.calculateDistance(this.airportSelect.fullName, this.pickUpLocation);
          }else{
            this.estimaterate = 0;
          }
        }
      },
      tabsChange(){
        if(this.tabs == 1){
          this.dropOffLocation = null;
          this.estimateRate();
        }else{
          this.pickUpLocation = null;
          this.estimateRate();
        }
      },
      formatDate(date) {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
      },
      formatTime(date) {
        const options = { hour: '2-digit', minute: '2-digit' };
        return date.toLocaleTimeString('vi-VN', options);
      },
      calculateDistance(origin, destination, retryCount = 3) {
        const functionUrl = 'https://asia-northeast1-boxwood-axon-389803.cloudfunctions.net/calculateDistance';
        const queryParams = `?origin=${encodeURIComponent(origin)}&destination=${encodeURIComponent(destination)}`;
        if (retryCount === 0) {
          this.isEstimating = false;
          this.showSnackbar("Error estimating rate. Please try again later.");
          return;
        }
        this.isEstimating = true;
        axios.get(functionUrl + queryParams)
          .then(response => {
            let rate = 20000;
            let nightFee = 0;
            let airportFee = 15000;

            const bookingHour = parseInt(this.timepicker.split(':')[0], 10);
            const isNightTime = bookingHour >= 22 || bookingHour < 5;
            // Cộng thêm phụ phí 50000 nếu là thời gian đêm
            if (isNightTime) {
              nightFee = 50000;
              if (this.vehicleSelect.code == '9s'){
                nightFee = 100000;
              }
            }
            if (this.vehicleSelect.code == '9s'){
              airportFee = 25000;
            }
            const distanceValue = response.data.routes[0].legs[0].distance.value;
            const estimatedDistance = Math.ceil(distanceValue / 1000); // Đổi đơn vị từ mét sang kilômét
            switch (this.vehicleSelect.code) {
              case '4s':
                rate = 20000
                break;
              case '7s':
                rate = 25000
                break;
              case '9s':
                rate = 50000
                break;
              default:
                break;
            }
            
            let estimatedRate = estimatedDistance * rate + airportFee + nightFee; // Tính giá trị estimaterate
            if (this.vehicleSelect.code == '4s') {
              if (isNightTime) {
                estimatedRate = Math.max(estimatedRate, 170000);
              } else {
                estimatedRate = Math.max(estimatedRate, 120000);
              }
            }else{
              if (this.vehicleSelect.code == '7s'){
                if (isNightTime) {
                  estimatedRate = Math.max(estimatedRate, 200000);
                } else {
                  estimatedRate = Math.max(estimatedRate, 150000);
                }
              }else{
                if (isNightTime) {
                  estimatedRate = Math.max(estimatedRate, 700000);
                } else {
                  estimatedRate = Math.max(estimatedRate, 600000);
                }
              }
            }
            this.estimaterate = estimatedRate; // Cập nhật giá trị estimaterate
            this.isEstimating = false;
          })
          .catch(error => {
            this.calculateDistance(origin, destination, retryCount - 1); // Retry the function
            console.error('Error calculating distance:', error);
          });
      },

      setPlace(place, type) {
        // Check if place.formatted_address contains place.name
        if (place.formatted_address.includes(place.name)) {
          // Remove place.name from place.formatted_address by replacing it with an empty string
          place.formatted_address = place.formatted_address.replace(place.name+',', '').trim();
        }
        if (type === 'dropOff') {
          this.dropOffLocation = place.name + ', ' + place.formatted_address;
          this.estimateRate();
        } else if (type === 'pickUp') {
          this.pickUpLocation = place.name + ', ' + place.formatted_address;
          this.estimateRate();
        }
      },
      setDate(date){
        this.date = this.formatDate(date);
        this.estimateRate();
      },
      setTime(time){
        this.time = time;
        this.timepicker = this.formatTime(time);
      },
      hideTimepicker(){
        if(!this.time){
          this.setTime(new Date());
        }
        this.estimateRate();
      },
      continueStep2(){
        if(this.tabs == 1){
          if(this.airportSelect&&this.dropOffLocation&&this.vehicleSelect&&this.date&&this.time&&this.estimaterate!=0){
            this.step = 2;
          }else{
            if(!this.dropOffLocation){
              this.snackbarText ="Please enter your Drop-off location";
            }else{
              if(!this.date){
                this.snackbarText ="Please select Pick-Up Date";
              }else{
                if(!this.time){
                  this.snackbarText ="Please select Pick-Up Time";
                }else{
                  this.snackbarText = "Rate cannot be estimated - Please reset page/f5";
                }
              }
            }
            this.snackbar = true;
          }
        }else{
          if(this.airportSelect&&this.pickUpLocation&&this.vehicleSelect&&this.date&&this.time&&this.estimaterate!=0){
            this.step = 2;
          }else{
            if(!this.pickUpLocation){
              this.snackbarText ="Please enter your Pick-up location";
            }else{
              if(!this.date){
                this.snackbarText ="Please select Pick-Up Date";
              }else{
                if(!this.time){
                  this.snackbarText ="Please select Pick-Up Time";
                }else{
                  this.snackbarText = "Rate cannot be estimated - Please reset page/f5";
                }
              }
            }
            this.snackbar = true;
          }
        }
      },
      showSnackbar(message) {
        if (!this.snackbar) {
          this.snackbarText = message;
          this.snackbar = true;
        }
      },
      completeRegistration(){
        if(this.airportSelect&&this.dropOffLocation&&this.vehicleSelect&&this.date&&this.time&&this.estimaterate!=0&&this.name&&this.phone){
            this.isSubmiting = true;
            // Lưu dữ liệu vào Firestore cho trường hợp tabs == 1
            let type = 'From Airport';
            if(this.tabs==1){
              type = 'From Airport';
            }else{
              type = 'To Airport';
            }
            db.collection('bookingData').add({
              "type": type,
              "airport": this.airportSelect.name,
              "dropOffLocation": this.dropOffLocation,
              "pickUpLocation": this.pickUpLocation,
              "vehicle": this.vehicleSelect.name,
              "date": this.date,
              "time": this.timepicker,
              "name": this.name,
              "phone": this.phone,
              "contactType": this.contactType,
              "estimaterate": this.estimaterate,
              to: ['booking.danangairporttransfer@gmail.com'],
              message: {
                subject: 'Booking Information', // Đặt chủ đề email theo ý muốn
                text: `
                  Hello,

                  You have received a new booking request with the following details:

                  Type: ${type}
                  Airport: ${this.airportSelect.name}
                  Drop-off Location: ${this.dropOffLocation}
                  Pick-up Location: ${this.pickUpLocation}
                  Vehicle: ${this.vehicleSelect.name}
                  Date: ${this.date}
                  Time: ${this.timepicker}
                  Name: ${this.name}
                  Phone: ${this.phone}
                  Contact Type: ${this.contactType}
                  Estimated Rate: ${this.estimaterate.toLocaleString('vi-VN')} đ

                  Thank you for choosing our service!

                  Regards,
                  Your Company Name
                `,
                html: `
                  <p>Hello,</p>

                  <p>You have received a new booking request with the following details:</p>

                  <ul>
                    <li><b>Type:</b> ${type}</li>
                    <li><b>Airport:</b> ${this.airportSelect.name}</li>
                    <li><b>Drop-off Location:</b> ${this.dropOffLocation}</li>
                    <li><b>Pick-up Location:</b> ${this.pickUpLocation}</li>
                    <li><b>Vehicle:</b> ${this.vehicleSelect.name}</li>
                    <li><b>Date:</b> ${this.date}</li>
                    <li><b>Time:</b> ${this.timepicker}</li>
                    <li><b>Name:</b> ${this.name}</li>
                    <li><b>Phone:</b> ${this.phone}</li>
                    <li><b>Contact Type:</b> ${this.contactType}</li>
                    <li><b>Estimated Rate:</b> ${this.estimaterate.toLocaleString('vi-VN')} đ</li>
                  </ul>

                  <p>Thank you for choosing our service!</p>

                  <p>Regards,<br>Your Company Name</p>
                `,
              }
              // Thêm các trường dữ liệu khác tùy theo yêu cầu của bạn
            })

            .then(docRef => {
              this.isCompleted = true;
              this.isSubmiting = false;
              console.log('Document written with ID: ', docRef.id);
              // Thực hiện các hành động khác sau khi dữ liệu được lưu thành công
            })
            .catch(error => {
              this.isSubmiting = false;
              console.error('Error adding document: ', error);
              this.showSnackbar("Failed to send data. Please try again later.");
              // Xử lý lỗi nếu có
            });
          }else {
            if (!this.name) {
              this.showSnackbar("Please enter your name");
            } else if (!this.phone) {
              this.showSnackbar("Please enter your phone number");
            } else if (!this.airportSelect) {
              this.showSnackbar("Please select an airport");
            } else if (!this.vehicleSelect) {
              this.showSnackbar("Please select a vehicle type");
            } else if (!this.date) {
              this.showSnackbar("Please select Pick-Up Date");
            } else if (!this.time) {
              this.showSnackbar("Please select Pick-Up Time");
            } else if (this.estimaterate === 0) {
              this.showSnackbar("Rate cannot be estimated - Please reset page/f5");
            }
          }
      },
      editItinerary(){
        this.step = 1;
      },
      backToHome() {
        // Đặt lại các biến dữ liệu về trạng thái ban đầu
        this.step = 1;
        this.airportSelect = { name: 'Da Nang International Airport', code: 'DAD01', fullName: 'Sân bay quốc tế Đà Nẵng, Nguyễn Văn Linh, Hòa Thuận Tây, Hải Châu, Đà Nẵng 550000, Việt Nam' };
        this.vehicleSelect = { name: '4 seater car', code: '4s' };
        this.time = null;
        this.timepicker = null;
        this.date = null;
        this.dropOffLocation = null;
        this.pickUpLocation = null;
        this.estimaterate = 0;
        this.tabs = null;
        this.name = '';
        this.phone = '';
        this.contactType = 'whatsapp';
        this.snackbar = false;
        this.snackbarText = '';
        this.isCompleted = false;
      }
    }
  };
  </script>
  