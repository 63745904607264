<template>
    <div class="custom-input">
      <span class="p-float-label">
        <GMapAutocomplete
          :placeholder="Gmapplaceholder"
          :value="GmapplaceValue"
          @place_changed="setPlace"
          :options="{
            bounds: {
              north: 16.74635484180607,
              south: 15.554301309789317,
              east: 108.54349984383497,
              west: 107.81759380376347
            },
            strictBounds: true
          }"
        ></GMapAutocomplete>
        <label>{{ Gmapplaceholder }}</label>
      </span>
      <div class="v-input__details"></div>
    </div>
  </template>
  <style>
  .custom-input label {
    position: absolute;
    left: 0 !important;
    top: 8px;
    font-size: 16px;
    pointer-events: none;
    transition: top 0.3s, font-size 0.3s, color 0.3s;
  }
  .custom-input .p-float-label .p-inputwrapper-filled ~ label{
    top: 0 !important;
    color: #929292 !important;
    font-weight: 400 !important;
  }
  .p-float-label input:focus ~ label{
    top: 0 !important;
    color: #00796B !important;
    font-weight: 400 !important;
  }
  .custom-input input:not(:placeholder-shown) ~ label {
    top: 0 !important;
    color: #929292 !important;
    font-weight: 400 !important;
  }
  </style>
  <script>
  export default {
    name: 'App',
    props: {
      Gmapplaceholder: String,
      GmapplaceValue: String,
    },
    data() {
      return {
        place: null,
      }
    },
    methods: {
      setPlace(place) {
        console.log(place);
        // Các xử lý khác khi chọn địa điểm
        this.$emit('setPlace',place)
      },
    },
  };
  </script>
  