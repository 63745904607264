<template>
    <div style="background-image: url('https://firebasestorage.googleapis.com/v0/b/boxwood-axon-389803.appspot.com/o/background-1.jpg?alt=media&token=b7bc4b89-005a-44ef-8971-f1c90b502cbc'); height: 100%;background-size: cover;">
    <app-navbar></app-navbar>
    <v-main>
        <v-container>
            <div class="about">
                <h1>Privacy Policy</h1>
            
                <p>
                  At Danang Airport Transfer, we value the privacy of our users and are committed to protecting their personal information.
                </p>
            
                <h2>Information Collection and Use</h2>
            
                <p>
                  When you use our application, we may collect certain personally identifiable information from you, including your name, phone number. This information is used solely for the purpose of providing the services you request and improving our application's functionality.
                </p>
            
                <h2>Log Data</h2>
            
                <p>
                  Like many applications, we collect log data that includes information about your device, browser type, IP address, pages visited, and the time and date of your visit. This data is used to analyze trends, administer the application, track user movements, and gather demographic information.
                </p>
            
                <h2>Cookies</h2>
            
                <p>
                  We may use cookies to enhance your user experience and provide personalized content. You can choose to disable cookies in your browser settings, but please note that some features of the application may not function properly without them.
                </p>
            
                <h2>Third-Party Services</h2>
            
                <p>
                  Our application may contain links to third-party websites and services that are not operated by us. We are not responsible for the privacy practices of these third-party services and recommend that you review their privacy policies before providing any personal information.
                </p>
            
                <h2>Security</h2>
            
                <p>
                  We take the security of your personal information seriously and employ industry-standard measures to protect it from unauthorized access, disclosure, or alteration. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                </p>
            
                <h2>Changes to This Privacy Policy</h2>
            
                <p>
                  We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated Privacy Policy on this page.
                </p>
            
                <h2>Contact Us</h2>
            
                <p>
                  If you have any questions or concerns about our Privacy Policy, please don't hesitate to contact us at privacy@danangairporttransfer.com.
                </p>
            </div>
        </v-container>
    </v-main>
    </div>
    <AppBottom/>
  </template>
  
  <script>
  import AppNavbar from '@/components/AppNavbar.vue';
  import AppBottom from '@/components/AppBottom.vue';
  export default {
    name: "PrivacyPolicy",
    components: {
      AppNavbar,
      AppBottom
    },
  };
  </script>
  
  <style>
  .about {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  </style>
  