<template>
    <div style="background-image: url('https://firebasestorage.googleapis.com/v0/b/boxwood-axon-389803.appspot.com/o/background-1.jpg?alt=media&token=b7bc4b89-005a-44ef-8971-f1c90b502cbc'); height: 100%;background-size: cover;">
    <app-navbar></app-navbar>
    <v-main>
        <v-container>
        <div class="about">
        <h1>About Us - Airport Transfer Service in Da Nang</h1>
        <p>Welcome to Da Nang Airport Transfer Service!</p>
        <p>
            We are a professional team providing high-quality airport transfer
            services in Da Nang city. With years of experience in this field, we are
            committed to offering you a comfortable, safe, and convenient travel
            experience.
        </p>
    
        <h2>Why Choose Our Da Nang Airport Transfer Service?</h2>
        <ol>
            <li>
            <strong>Convenience and Reliability:</strong> We understand that
            getting from the airport to your hotel and vice versa is an essential
            part of your trip. With our airport transfer service, you won't have to
            worry about finding a taxi or using public transportation. We ensure
            timely pickups and drop-offs, making sure you have a smooth travel
            itinerary.
            </li>
            <li>
            <strong>Diverse Fleet of Vehicles:</strong> We provide a range of
            vehicle options to cater to our customers' needs. From luxurious sedans
            for simple business trips to spacious SUVs for group travelers, we have
            a suitable option for all your transportation needs.
            </li>
            <li>
            <strong>Professional Drivers:</strong> Our team of drivers is
            professionally trained and friendly. They will greet you with a smile
            and provide attentive service, ensuring you have a safe and comfortable
            journey.
            </li>
            <li>
            <strong>24/7 Service:</strong> We are available to serve you anytime,
            day or night. Our customer support team is always ready to assist and
            address any inquiries you may have.
            </li>
        </ol>
    
        <h2>Book Your Da Nang Airport Transfer Service with Us Today!</h2>
        <p>
            Experience convenience and comfort in your travels in Da Nang by booking
            our services today. We promise you reliable and top-quality service.
        </p>
    
        <h2>Contact Us:</h2>
        <p>
            Phone: +84 702 650 065
            <br />
            Email: info@danangairporttransfer.com
            <br />
            Website: www.danangairporttransfer.com
        </p>
    
        <p>
            Thank you for choosing Da Nang Airport Transfer Service. We look forward
            to serving you on your trip to Da Nang!
        </p>
        </div>
        </v-container>
    </v-main>
    </div>
    <AppBottom/>
  </template>
  
  <script>
  import AppNavbar from '@/components/AppNavbar.vue';
  import AppBottom from '@/components/AppBottom.vue';
  export default {
    name: "AboutPage",
    components: {
      AppNavbar,
      AppBottom
    },
  };
  </script>
  
  <style>
  .about {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  </style>
  