import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router';
import VueGoogleMaps from '@fawmi/vue-google-maps'
import PrimeVue from 'primevue/config';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Calendar from 'primevue/calendar';

import "primevue/resources/themes/lara-light-indigo/theme.css";     
//core
import "primevue/resources/primevue.min.css";

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
// Thay thế thông tin cấu hình Firebase của bạn ở đây
const firebaseConfig = {
  apiKey: "AIzaSyCgN0G4bBEbNXxhqHrg2cTOtzb4p8dqzmw",
  authDomain: "boxwood-axon-389803.firebaseapp.com",
  projectId: "boxwood-axon-389803",
  storageBucket: "boxwood-axon-389803.appspot.com",
  messagingSenderId: "654490864176",
  appId: "1:654490864176:web:917726aa4ec369bccfbbf5",
  measurementId: "G-9GQGRJ0VX0"
};
// Khởi tạo Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig)
// Khởi tạo Firestore và gán cho biến 'db'
const db = firebaseApp.firestore();

// Xuất Firestore để có thể sử dụng trong các thành phần khác
export default db;
//theme


loadFonts()

createApp(App)
  .use(router)
  .use(vuetify)
  .use(PrimeVue)
  .use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCJy-4gdOa2h823QdyuaAVSMWDvzge8lX4',
        libraries: "places"
    },
  })
  .component('VueDatePicker', VueDatePicker)
  .component('Calendar', Calendar)
  .mount('#app')
