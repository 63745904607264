<template>
    <div class="custom-input">
        <span class="p-float-label" >
          <Calendar v-bind:modelValue="TimePickerValue" @update:modelValue="setTime" @hide="hideTimepicker" timeOnly touchUI></Calendar>
          <label for="birth_date">Pick-Up Time</label>
        </span>
        <div class="v-input__details"></div>
    </div>
    
  </template>
  
  <style>
  .custom-input {
    position: relative;
  }
  
  .custom-input input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #ababab;
    border-radius: 0;
    height: 52px;
    font-size: 16px;
    padding: 0px;
  }
  
  
  .custom-input input:focus {
    outline: none;
    border-bottom: 2px solid purple;
  }
  
  .custom-input input:not(:placeholder-shown) ~ label {
    top: -16px;
    font-size: 12px;
    color: purple;
    font-weight: bold;
    transition: top 0.3s, font-size 0.3s, color 0.3s;
  }
  
  .custom-input label {
    position: absolute;
    left: 0 !important;
    top: 8px;
    font-size: 16px;
    pointer-events: none;
    transition: top 0.3s, font-size 0.3s, color 0.3s;
  }
  
  </style>
  
  <script>
  export default {
  name: 'App',
  props: {
    TimePickerValue: String,
  },
  data() {
    return {
        time: new Date()
    }
  },
  methods: {
    setTime(time){
      this.time = time;
      this.$emit('setTime',time)
    },
    hideTimepicker(){
      this.$emit('hideTimepicker')
    }
  }
  }
  </script>