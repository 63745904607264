<template>
  <v-navigation-drawer
    v-model="drawer"
    temporary
  >
    <v-list-item
      prepend-avatar="https://firebasestorage.googleapis.com/v0/b/boxwood-axon-389803.appspot.com/o/favicon.png?alt=media&token=32043c02-6f40-4938-b061-c85d0549963e"
      title="Welcome !"
    ></v-list-item>

    <v-divider></v-divider>

    <v-list density="compact" nav>
      <v-list-item prepend-icon="mdi-home" title="Home" value="home" @click="navigateTo('home')"></v-list-item>
      <v-list-item prepend-icon="mdi-information" title="About" value="about" @click="navigateTo('about')"></v-list-item>
      <v-list-item prepend-icon="mdi-text-box-check" title="Privacy Policy" value="PrivacyPolicy" @click="navigateTo('PrivacyPolicy')"></v-list-item>
      <v-list-item prepend-icon="mdi-shield-sun-outline" title="Terms of Service" value="TermsOfService" @click="navigateTo('TermsOfService')"></v-list-item>
      <v-list-item prepend-icon="mdi-contacts" title="Contact Us" value="ContactUs" @click="openMap"></v-list-item>
    </v-list>
  </v-navigation-drawer>
  <v-app-bar app color="teal-darken-1" dark>
    <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
    <v-toolbar-title class="flex-grow-1 text-center">Da Nang Airport Transfer</v-toolbar-title>
    <v-btn icon @click="changeLanguage">
      <v-icon>{{ currentLanguage === 'en' ? 'mdi-earth' : 'mdi-earth-plus' }}</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      currentLanguage: 'en',
      drawer: null
    };
  },
  methods: {
    toggleDrawer() {
      // Hàm này sẽ được gọi khi nhấp vào icon menu
      // Thêm xử lý tương ứng để mở hoặc đóng menu
    },
    changeLanguage() {
      // Hàm này sẽ được gọi khi nhấp vào icon đổi ngôn ngữ
      // Thêm xử lý tương ứng để thay đổi ngôn ngữ
      this.currentLanguage = this.currentLanguage === 'en' ? 'ko' : 'en';
    },
    navigateTo(route) {
      // Điều hướng đến route được chọn
      this.$router.push({ name: route });
    },
    openMap(){
      window.location.href = `https://g.page/r/CVP2e17_CaQ5EB0`;
    },
  },
};
</script>
