<template>
    <v-layout class="overflow-visible" style="height: 56px;">
      <v-bottom-navigation v-model="value" active>
        <v-btn class="text-teal-darken-2" @click="openMap">
          <Icon icon="mdi:google-my-business" height="20"/>
          <span>Contact Us</span>
        </v-btn>
        
        <v-btn class="text-teal-darken-2" @click="openWhatsapp">
          <v-icon>mdi-whatsapp</v-icon>
           WhatsApp
        </v-btn>

        <v-btn class="text-teal-darken-2" @click="openWeChatChat">
          <v-icon>mdi-wechat</v-icon>
            Wechat
        </v-btn>
      </v-bottom-navigation>
    </v-layout>
  </template>
  <script>
  import { Icon } from '@iconify/vue';
  export default {
    data: () => ({}),
    components: {
		Icon,
	},
  methods: {
    // Hàm để mở liên kết WeChat với tài khoản cụ thể
    openWeChatChat() {
      //const accountNumber = '84702650065';
      window.location.href = `weixin://dl/chat?wxid_thanhlinhphung`;
    },
    openWhatsapp(){
      const accountNumber = '84899254525';
      window.location.href = `https://api.whatsapp.com/send/?phone=${encodeURIComponent(accountNumber)}&text&type=phone_number&app_absent=0`;
    },
    openMap(){
      window.location.href = `https://g.page/r/CVP2e17_CaQ5EB0`;
    },
  }
  }
</script>